import React from "react"
import { Label } from "../form"
import FormikFileInputSingle from "../form/fileInput/formik-file-input-single"

const CovidUpload = ({ colorTheme, name, label }) => {
  const [fileErrorText, setFileErrorText] = React.useState([])
  return (
    <section>
      <Label>{label}</Label>
      <FormikFileInputSingle
        className="span-grid"
        name={name}
        label="Choose file"
        fileType="all"
        maxSize={5}
        errorText={fileErrorText}
        setErrorText={setFileErrorText}
        colorTheme={colorTheme}
      />
      {fileErrorText
        ? fileErrorText.map(err => (
          <p style={{ color: "#FF0000" }} key={err}>
            <strong>{err}</strong>
          </p>
        ))
        : null}
      <br />
    </section>
  )
}

export default CovidUpload
