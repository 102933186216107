import * as Yup from "yup"
import { phoneRegex, shareList, dateRegex, standardRequiredText } from "../../constants"
import { parseDateString, switchDateFormat } from "../../utils/form-data"
import moment from "moment"

const referenceFields = {
  name: Yup.string().required(standardRequiredText),
  company: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  contact: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  address: Yup.string().required(standardRequiredText),
}

const companyFields = {
  company_name: Yup.string().required(standardRequiredText),
  job_title: Yup.string().required(standardRequiredText),
  date_from: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  date_to: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  reason_for_leaving: Yup.string(),
  duties: Yup.string(),
}

export const validationSchema = Yup.object().shape({
  consultant_name: Yup.string().required(standardRequiredText),
  title: Yup.string().required(standardRequiredText),
  first_name: Yup.string().required(standardRequiredText),
  surname: Yup.string().required(standardRequiredText),
  address_1: Yup.string().required(standardRequiredText),
  address_2: Yup.string(),
  address_3: Yup.string(),
  address_town: Yup.string().required(standardRequiredText),
  postcode: Yup.string().required(standardRequiredText),
  country: Yup.string().required(standardRequiredText),
  postcode: Yup.string().required(standardRequiredText),
  date_of_birth: Yup.date()
    .transform(parseDateString)
    .min(
      moment(new Date(1900, 1, 1)).toDate(),
      "Must be a date after 01/01/1900"
    )
    .required(standardRequiredText),
  mobile: Yup.string()
    .matches(phoneRegex, "Must be a valid phone number")
    .required(standardRequiredText),
  landline: Yup.string().matches(phoneRegex, "Must be a valid phone number"),
  email: Yup.string()
    .email("Must be a valid email address in the format 'example@example.com'")
    .required(standardRequiredText),
  national_insurance: Yup.string().required(standardRequiredText),
  GDPR: Yup.number(),
  has_full_driving_licence: Yup.string().required(standardRequiredText),
  date_arrived: Yup.string().when('nationality', {
    is: (nationality) => nationality !== "United Kingdom",
    then: Yup.string().matches(
      dateRegex,
      "Must be a valid date in the format dd/mm/yyyy"
    ).required(standardRequiredText),
  }),
  share_code: Yup.string().when(['nationality', 'date_arrived'], {
    is:
      (nationality, date_arrived) => shareList.includes(nationality) && date_arrived && switchDateFormat(date_arrived) <= new Date("12/31/2020"),
    then: Yup.string().required(standardRequiredText).max(9, "Max 9 Characters"),
  }),
  passport_number: Yup.number().typeError("Must be a number"),
  passport_issue_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  passport_expiry_date: Yup.string().matches(
    dateRegex,
    "Must be a valid date in the format dd/mm/yyyy"
  ),
  eligible_uk: Yup.string().required(standardRequiredText),
  work_permit_uk: Yup.string().required(standardRequiredText),
  move_to_uk_decade: Yup.string().required(standardRequiredText),
  languages: Yup.string().required(standardRequiredText),
  other_languages: Yup.string().when("languages", {
    is: "Yes",
    then: Yup.string().required("Must enter other language"),
  }),
  hear_about_us: Yup.string().required(standardRequiredText),
  hear_about_us_other: Yup.string().when(
    "hear_about_us",
    {
      is: "Friend",
      then: Yup.string().required(standardRequiredText),
    }
  ).when(
    "hear_about_us",
    {
      is: "Other",
      then: Yup.string().required(standardRequiredText),
    }
  ),
  convictions: Yup.string().required(standardRequiredText),
  convictions_details: Yup.string().when("convictions", {
    is: "Yes",
    then: Yup.string().required("Must enter conviction details"),
  }),
  barring_decision: Yup.string().required(standardRequiredText),
  dbs_number: Yup.string(),
  qualified_teacher_certification_number: Yup.string(),
  driving_licence: Yup.string().required(standardRequiredText),
  method_of_transport: Yup.array()
    .min(1)
    .required(standardRequiredText),
  car_owner: Yup.string().when("driving_licence", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  use_own_car: Yup.string().when("driving_licence", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  car_insurance: Yup.string().when("driving_licence", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  mot: Yup.string().when("driving_licence", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  education: Yup.object().shape({
    is_required: Yup.string().required(standardRequiredText),
    facility_name: Yup.string(),
    course_name: Yup.string(),
    start_date: Yup.date()
      .transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ),
    end_date: Yup.date()
      .transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ),
    qualifications: Yup.string(),
    address: Yup.string(),
    contact_name: Yup.string(),
    contact_number: Yup.string(),
  }),
  employment_history: Yup.array()
    .of(Yup.object().shape(companyFields))
    .min(1),
  employer_contact: Yup.number(),
  references: Yup.array()
    .of(Yup.object().shape(referenceFields))
    .min(1),
  references_consent: Yup.number().required(standardRequiredText),
  age: Yup.string().required(standardRequiredText),
  sex: Yup.string().required(standardRequiredText),
  gender: Yup.string().required(standardRequiredText),
  gender_term: Yup.string().when("gender", {
    is: "No",
    then: Yup.string().required(standardRequiredText),
  }),
  sexual_orientation: Yup.string().required(standardRequiredText),
  religion: Yup.string().required(standardRequiredText),
  origin: Yup.string().required(standardRequiredText),
  disability: Yup.string().required(standardRequiredText),
  disability_info: Yup.string().when("disability", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  disability_requirement: Yup.string(),
  confirm_health: Yup.string().required(standardRequiredText),
  mrsa: Yup.string().required(standardRequiredText),
  mrsa_date: Yup.date().when("mrsa", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  cdiff: Yup.string().required(standardRequiredText),
  cdiff_date: Yup.date().when("cdiff", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  confirm_health_info: Yup.string()
    .when("cdiff", {
      is: "Yes",
      then: Yup.string().required(standardRequiredText),
    })
    .when("mrsa", {
      is: "Yes",
      then: Yup.string().required(standardRequiredText),
    }),
  pox_shingles: Yup.string().required(standardRequiredText),
  pox_shingles_date: Yup.date().when("pox_shingles", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  bbv: Yup.string().required(standardRequiredText),
  bbv_date: Yup.date().when("bbv", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  tuberculosis: Yup.string().required(standardRequiredText),
  tuberculosis_info: Yup.string().when("tuberculosis", {
    is: "No",
    then: Yup.string().required(standardRequiredText),
  }),
  tuberculosis_bcg: Yup.string().required(standardRequiredText),
  tuberculosis_date: Yup.date().when("tuberculosis_bcg", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  tuberculosis_cough: Yup.string().required(standardRequiredText),
  tuberculosis_weight: Yup.string().required(standardRequiredText),
  tuberculosis_fever: Yup.string().required(standardRequiredText),
  tuberculosis_contact: Yup.string().required(standardRequiredText),
  ebola_countries: Yup.string().required(standardRequiredText),
  ebola_info: Yup.string().when("ebola_countries", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  epp: Yup.string().required(standardRequiredText),
  triple_vaccination: Yup.string().required(standardRequiredText),
  triple_vaccination_date: Yup.date().when("triple_vaccination", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  polio: Yup.string().required(standardRequiredText),
  polio_date: Yup.date().when("polio", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  tetanus: Yup.string().required(standardRequiredText),
  tetanus_date: Yup.date().when("tetanus", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  varicella: Yup.string().required(standardRequiredText),
  varicella_date: Yup.date().when("varicella", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  mmr: Yup.string().required(standardRequiredText),
  mmr_date: Yup.date().when("mmr", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  hepatitis: Yup.string().required(standardRequiredText),
  hepatitis_course_1: Yup.string(),
  hepatitis_course_2: Yup.string(),
  hepatitis_course_3: Yup.string(),
  hepatitis_boost_1: Yup.string(),
  hepatitis_boost_2: Yup.string(),
  hepatitis_boost_3: Yup.string(),
  covid_first_dose: Yup.string().required(standardRequiredText),
  covid_first_dose_name: Yup.string().when("covid_first_dose", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  covid_first_dose_batch_number: Yup.string().when("covid_first_dose", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  covid_first_dose_date: Yup.date().when("covid_first_dose", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  covid_second_dose: Yup.string().when("covid_first_dose", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  covid_second_dose_name: Yup.string().when("covid_second_dose", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  covid_second_dose_batch_number: Yup.string().when("covid_second_dose", {
    is: "Yes",
    then: Yup.string().required(standardRequiredText),
  }),
  covid_second_dose_date: Yup.date().when("covid_second_dose", {
    is: "Yes",
    then: Yup.date().transform(parseDateString)
      .min(
        moment(new Date(1900, 1, 1)).toDate(),
        "Must be a date after 01/01/1900"
      ).required(standardRequiredText),
  }),
  proof_of_covid_dose: Yup.mixed().nullable().when("covid_first_dose", {
    is: "Yes",
    then: Yup.mixed().nullable().required(standardRequiredText),
  }),
  proof_of_covid_dose_2: Yup.mixed().nullable().when("covid_second_dose", {
    is: "Yes",
    then: Yup.mixed().nullable().required(standardRequiredText),
  }),
  consultancy_paye: Yup.number(),
  job_description_reviewed: Yup.number().required(standardRequiredText),
  consultancy_agree: Yup.number(),
  consultancy_type: Yup.string(),
  consultancy_name: Yup.string(),
  consultancy_company_number: Yup.string(),
  consultancy_utr: Yup.string(),
  consultancy_active: Yup.number(),
  right_to_work: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  proof_of_address_1: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  proof_of_address_2: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  proof_of_national_insurance: Yup.mixed()
    .nullable()
    .required(standardRequiredText),
  proof_of_name_change: Yup.mixed().nullable(),
  passport_upload: Yup.mixed().nullable(),
  driving_licence_upload: Yup.mixed().nullable(),
  dbs_upload: Yup.mixed().nullable(),
  qualified_teacher_certification_upload: Yup.mixed().nullable(),
})
