export const initialValues = {
  consultant_name: "",
  title: "",
  first_name: "",
  surname: "",
  address_1: "",
  address_2: "",
  address_3: "",
  address_town: "",
  postcode: "",
  country: "",
  date_of_birth: "",
  mobile: "",
  landline: "",
  email: "",
  national_insurance: "",
  GDPR: 0,
  has_full_driving_licence: "",
  method_of_transport: [],
  nationality: "",
  date_arrived: "",
  share_code: "",
  passport_number: "",
  passport_issue_date: "",
  passport_expiry_date: "",
  eligible_uk: "",
  work_permit_uk: "",
  languages: "",
  other_languages: "",
  hear_about_us: "",
  hear_about_us_other: "",
  move_to_uk_decade: "",
  convictions: "",
  convictions_details: "",
  barring_decision: "",
  dbs_number: "",
  dbs_upload: null,
  qualified_teacher_certification_number: "",
  qualified_teacher_certification_upload: null,
  driving_licence: "",
  car_owner: "",
  use_own_car: "",
  car_insurance: "",
  mot: "",
  education: {
    is_required: "",
    facility_name: "",
    course_name: "",
    start_date: "",
    end_date: "",
    qualifications: "",
    address: "",
    contact_name: "",
    contact_number: "",
    flt_plant_license: "n/a",
  },
  employment_history: [
    {
      company_name: "",
      job_title: "",
      date_from: "",
      date_to: "",
      reason_for_leaving: "",
      duties: "",
    },
  ],
  employer_contact: 0,
  references: [
    {
      name: "",
      company: "",
      job_title: "",
      contact: "",
      email: "",
      address: "",
    },
  ],
  references_consent: 0,
  age: "",
  sex: "",
  gender: "",
  gender_term: "",
  sexual_orientation: "",
  religion: "",
  origin: "",
  disability: "",
  disability_info: "",
  disability_requirement: "",
  confirm_health: "",
  mrsa: "",
  mrsa_date: "",
  cdiff: "",
  cdiff_date: "",
  confirm_health_info: "",
  pox_shingles: "",
  pox_shingles_date: "",
  bbv: "",
  bbv_date: "",
  tuberculosis: "",
  tuberculosis_info: "",
  tuberculosis_bcg: "",
  tuberculosis_date: "",
  tuberculosis_cough: "",
  tuberculosis_weight: "",
  tuberculosis_fever: "",
  tuberculosis_contact: "",
  ebola_countries: "",
  ebola_info: "",
  epp: "",
  triple_vaccination: "",
  triple_vaccination_date: "",
  polio: "",
  polio_date: "",
  tetanus: "",
  tetanus_date: "",
  varicella: "",
  varicella_date: "",
  mmr: "",
  mmr_date: "",
  hepatitis: "",
  hepatitis_course_1: "",
  hepatitis_course_2: "",
  hepatitis_course_3: "",
  hepatitis_boost_1: "",
  hepatitis_boost_2: "",
  hepatitis_boost_3: "",
  covid_first_dose: "",
  covid_first_dose_name: "",
  covid_first_dose_batch_number: "",
  covid_first_dose_date: "",
  covid_second_dose: "",
  covid_second_dose_name: "",
  covid_second_dose_batch_number: "",
  covid_second_dose_date: "",
  proof_of_covid_dose: null,
  proof_of_covid_dose_2: null,
  consultancy_paye: 0,
  job_description_reviewed: 0,
  consultancy_agree: 0,
  consultancy_type: "",
  consultancy_name: "",
  consultancy_company_number: "",
  consultancy_utr: "",
  consultancy_active: 0,
  right_to_work: null,
  proof_of_address_1: null,
  proof_of_address_2: null,
  proof_of_national_insurance: null,
  proof_of_name_change: null,
  passport_upload: null,
  driving_licence_upload: null,
}
